import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  
    /* {
    sousId: 0,
    stakingToken: tokens.test,
    earningToken: tokens.test,
    contractAddress: {
      64668: '0xa7b37d6abe333e85b49dec00029517746bbb8fff',
      3797: '0xa7b37d6abe333e85b49dec00029517746bbb8fff',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '35',
    sortOrder: 1,
    isFinished: false,
  },    
  // 0x5Fc67A95649488Ad54488B2650dad5D9922F6188 alvusdtlp alv lp pool contcat smartchif factory 
  // 0xF3eF47559cb8081D5b6Db677E74898Fb2F3B5c77 ausdt walv  pool contcat smartchif factory 
  */
 
 {
    sousId: 5,
    stakingToken: tokens.busd,
    earningToken: tokens.walv,
    contractAddress: {
      64668: '0x9866cf0013d4828b6b72D2339e2327698156a928',
      3797: '0x9866cf0013d4828b6b72D2339e2327698156a928',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0000005937',  // 
  }, 
  
   {
    sousId: 6,
    stakingToken: tokens.alvusdtlp,
    earningToken: tokens.ausdt,
    contractAddress: {
      64668: '0xaD16c0Ab5EDBb30845D67d555270c9c68B209003',
      3797: '0xaD16c0Ab5EDBb30845D67d555270c9c68B209003',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00000037017', //   0.00457875457
  },
 
  {
    sousId: 7,
    stakingToken: tokens.alvanalp,
    earningToken: tokens.ana,
    contractAddress: {
      64668: '0x35acE9e057EC510564c28e5DF88efF4aa26690d0',
      3797: '0x35acE9e057EC510564c28e5DF88efF4aa26690d0',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.000578', //   2314
  },

 
// *********************  Finished ******************* 
  
  
  
  {
    sousId: 1,
    stakingToken: tokens.busd,
    earningToken: tokens.walv,
    contractAddress: {
      64668: '0x38D5177aA8A6bC3d0B6eB6F9B8c9D3B60A9474C2',
      3797: '0x38D5177aA8A6bC3d0B6eB6F9B8c9D3B60A9474C2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0000005937',  // 0.01635802469 79,342.74%  11
  }, 
  {
    sousId: 4,
    stakingToken: tokens.alvanalp,
    earningToken: tokens.ana,
    contractAddress: {
      64668: '0x33ab838d6041540ec01741ca20b8be70ee13d120',
      3797: '0x33ab838d6041540ec01741ca20b8be70ee13d120',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.0268', //   2314
  },
  
  {
    sousId: 3,
    stakingToken: tokens.alvusdtlp,
    earningToken: tokens.ausdt,
    contractAddress: {
      64668: '0xFBEA2EcFCfb9C1EB206f0A1FAD29fbEfD9bD279e',
      3797: '0xFBEA2EcFCfb9C1EB206f0A1FAD29fbEfD9bD279e',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.00000037017', //   0.00457875457
  },
  
  
  
  {
    sousId: 2,
    stakingToken: tokens.alvanalp,
    earningToken: tokens.ana,
    contractAddress: {
      64668: '0xbe00a0038441d9639ae616843209b15c19b9e65d',
      3797: '0xbe00a0038441d9639ae616843209b15c19b9e65d',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    sortOrder: 999,
    tokenPerBlock: '0.082', //   4629
  }, 
  
]

export default pools
